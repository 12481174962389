import styles from '@buddieshr/styles/Home.module.scss';
import MetaHeader from '@buddieshr/components/metaHeader';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Header from '@buddieshr/components/header';
import Footer from '@buddieshr/components/footer';
import Image from 'next/image';
import { Button, Divider, Tag } from 'antd';
import WhyCare from '@buddieshr/components/landing/whyCare';
import TextBackground from '@buddieshr/components/text/textBackground';
import TextUnderlined from '@buddieshr/components/text/textUnderlined';
import TrustedBy from '@buddieshr/components/trustedBy';
import Benefits from '@buddieshr/components/benefits';
import G2Logos from '@buddieshr/components/g2Logos';
import { BENEFITS_BUDDIESHR } from '@buddieshr/utils/constants';
import ReviewSnippet from '@buddieshr/components/reviewSnippet';
import Link from 'next/link';
import DreamTeam from '@buddieshr/components/dreamTeam';
import BuddiesHREffect from '@buddieshr/components/landing/buddiesHREffect';
import StopProcrastinating from '@buddieshr/components/landing/stopProcrastinating';
import NotForYouIf from '@buddieshr/components/landing/notForYouIf';
import VisionPanel from '@buddieshr/components/landing/visionPanel';
import LovePanel from '@buddieshr/components/landing/lovePanel';
import PageWrapper from '@buddieshr/components/templates/pageWrapper';
import ImpactBottomLine from '@buddieshr/components/impactBottomLine';
import PricingButtonHint from '@buddieshr/components/pricing/pricingbuttonHint';
import TextColored from '@buddieshr/components/text/textColored';

export default function Home({ refSource }) {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <>
      <MetaHeader
        title={t('metaTitle')}
        description={t('descriptionContent')}
        currentURL={`https://buddieshr.com/${
          router.locale === 'en' ? '' : router.locale
        }`}
        locale={router.locale}
      />
      <Header />
      <ReviewSnippet />
      <main className={styles.main}>
        <div className={styles.firstSectionWrapper}>
          <div className={styles.firstSectionInner}>
            <div className={styles.headerSection}>
              <div className={styles.headerSectionInner}>
                <div>
                  {/* <Image
                    src="/img/featuredbyslack.png"
                    width={170}
                    height={84}
                    alt="featured by slack"
                  /> */}
                  <Image
                    src="/img/featuredbyslack_h.png"
                    width={600 / 3}
                    height={178 / 3}
                    alt="featured by slack"
                  />
                </div>
                <h1 className={styles.mainTitle}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    The #1&nbsp;&nbsp;
                    <TextBackground>Employee Engagement</TextBackground>{' '}
                    Software in Slack
                    <Image
                      src="/img/slack_80.png"
                      alt="logo slack"
                      width={26}
                      height={26}
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                </h1>
                <div className={styles.subtitle}>
                  <div>
                    We help <b>People Teams and Founders</b> build an amazing
                    company culture that <b>attracts and retains top talent</b>
                    <Image
                      src="/img/animated-emojis/Smiling%20Face%20with%20Heart-Eyes.png"
                      alt="Smiling Face with Heart-Eyes"
                      width="28"
                      height="28"
                      style={{ marginLeft: '6px' }}
                    />
                  </div>
                </div>

                <div className={styles.actions}>
                  <Button
                    // icon={<CaretRightOutlined />}
                    type="link"
                    size="large"
                    href="#products"
                  >
                    Discover our Buddies&nbsp;👯
                  </Button>
                  <Link href="/get-started">
                    <Button
                      // icon={<CaretRightOutlined />}
                      type="primary"
                      size="large"
                      style={{ minWidth: 230 }}
                    >
                      Get started
                    </Button>
                  </Link>
                </div>
                <div className={styles.ratedTopPanel}>
                  <G2Logos
                    withTitle={false}
                    withBackground={false}
                    className={styles.g2LogosTop}
                  />
                </div>
                <div className={styles.companyLogosTop}>
                  <Image
                    width={139}
                    height={50}
                    src="/img/clients/getcensus.com.svg"
                    alt="getcensus.com uses Buddieshr"
                    style={{ width: 109, marginTop: '12px', height: 'auto' }}
                  />
                  <Image
                    width={99}
                    height={50}
                    src="/img/clients/goodr.com.png"
                    alt="goodr.com uses Buddieshr"
                    style={{
                      width: 70,
                      height: 'auto',
                      marginTop: 16,
                      marginBottom: 0,
                    }}
                  />
                  <Image
                    width={240 * 0.8}
                    height={50 * 0.8}
                    src="/img/clients/docplanner.png"
                    alt="docplanner group trusts Buddieshr"
                    style={{ height: 'auto' }}
                  />
                  <Image
                    width={145}
                    height={50}
                    src="/img/clients/veedio.svg"
                    alt="veedio uses Buddieshr"
                    style={{ width: 90, height: 'auto' }}
                  />
                  <Image
                    width={145}
                    height={50}
                    src="/img/clients/essilor.png"
                    alt="essilor uses Buddieshr"
                    style={{ width: 145, height: 'auto' }}
                  />
                  <Image
                    width={125}
                    height={50}
                    src="/img/clients/legalsoft.svg"
                    alt="legalsoft uses Buddieshr"
                    style={{ width: 145, height: 'auto', marginTop: '8px' }}
                  />
                </div>
                <div style={{ color: '#696868' }}>
                  And thousands more <b>badass companies</b> like these, shaping
                  the workplace culture of their dreams!
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <TrustedBy hideInstall /> */}
        <LovePanel
          hideAppNames
          displayLinkedin={true}
          small
          showStars
          onlyFavorites={true}
          showCompanyLogo
          hideTitle={true}
          style={{ marginTop: 0 }}
        />
        <DreamTeam />

        <Benefits
          app={{
            benefits: BENEFITS_BUDDIESHR,
          }}
        />
        <BuddiesHREffect />
        <PageWrapper width={1510}>
          <div className={styles.whyWrapper}>
            <Tag color={'blue'} className={styles.bullshitTag}>
              Impact & KPIs
            </Tag>
            <h2>
              How does BuddiesHR impact the bottom line?
              <br /> Look at these numbers! 😱
            </h2>
            <WhyCare short />
            <ImpactBottomLine />
            <div className={styles.finalCTA} style={{ marginTop: 34 }}>
              <Link href="/pricing">
                <Button
                  type="link"
                  size="large"
                  style={{ minWidth: 220, color: '#69b1ff' }}
                >
                  View pricing
                </Button>
              </Link>
              <Link href="/get-started">
                <Button
                  style={{
                    color: '#fff',
                    backgroundColor: '#101957',
                    minWidth: 220,
                  }}
                  type="primary"
                  size="large"
                >
                  Start for free
                </Button>
              </Link>
            </div>
            <div style={{ width: '400px', maxWidth: '100%', margin: 'auto' }}>
              <PricingButtonHint />
            </div>
          </div>
        </PageWrapper>
        <div className={`${styles.landingQuote}`}>
          <div className={`${styles.landingQuoteInner}`}>
            <div>
              Engaged employees are{' '}
              <TextUnderlined>18% more productive</TextUnderlined> at work.
              <Link
                style={{ color: '#b1b8fe', fontSize: 12, marginLeft: 12 }}
                target="_blank"
                href="https://www.gallup.com/workplace/236927/employee-engagement-drives-growth.aspx"
              >
                Source
              </Link>
            </div>
            <div>
              <h3 style={{ marginTop: 24, color: 'white' }}>
                Let’s make that your team!
              </h3>
            </div>
          </div>
        </div>
        <StopProcrastinating />
        <NotForYouIf />

        <VisionPanel />
        <div className={styles.finalCall}>
          <div
          // style={{ fontSize: "144px" }}
          >
            <Image
              src="/img/animated-emojis/Thinking%20Face.png"
              alt="Smiling Face with Tear"
              width="125"
              height="125"
              style={{ marginBottom: 12 }}
            />
          </div>
          <h2>You read all of this and didn’t install any app yet?</h2>
          <div>
            <Link href="/get-started">
              <Button type="primary" size="large" style={{ minWidth: 220 }}>
                Get started now
              </Button>
            </Link>
            <Link target="_blank" href="https://blog.buddieshr.com">
              <Button type="link" size="large" style={{ minWidth: 220 }}>
                Read our Blog
              </Button>
            </Link>
          </div>
        </div>
      </main>
      <Footer refSource={refSource} />
    </>
  );
}
