import { Button, Tag } from 'antd';
import React from 'react';
import styles from '@buddieshr/styles/components/stopProcrastinating.module.scss';
import NumberInstalls from '../numberInstalls';
import Image from 'next/image';
import Link from 'next/link';

const StopProcrastinating = () => (
  <div className={styles.wrapper}>
    <div className={styles.inner}>
      {/* <div className={styles.icon}>⏰</div> */}
      <div className={styles.icon}>
        <Image
          src="/img/animated-emojis/Alarm%20Clock.png"
          alt="Alarm Clock"
          width="158"
          height="158"
        />
      </div>
      <h3 className={styles.title}>Stop procrastinating...</h3>
      <div className={styles.subtitle}>
        It’s time to create the culture you’ve always dreamed of!
        <br />
        <br />
        Try BuddiesHR for Free, Your team Will Thank You.
      </div>
      <div className={styles.actions}>
        <Link href="/get-started">
          <Button type="primary" size="large" className={styles.a1}>
            Get started NOW!
          </Button>
        </Link>

        <div className={styles.a2}>
          <NumberInstalls />
        </div>
      </div>
    </div>
  </div>
);

export default StopProcrastinating;
